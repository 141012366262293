<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Edit Placement Items</h1>
    </page-header>
    <page-body>
      <section class="section-1 bg-block mb-4">
        <h4>
          {{ locationDetail.org_name }}
        </h4>
        <h1 class="sm mb-4">{{ translations.tcEditPlacementItems }}</h1>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">{{ translations.tcPlacementItems }}</h2>
          <ToggleArrow @click="section2 = !section2" section="section2" :status="section2"></ToggleArrow>
        </header>
        <div class="body" v-if="section2">
          <div class="presentation-specs mb-5 list-border" v-for="(itm, index) in allItemsTranslated" :key="index">
            <b-row style="margin-bottom: 0px">
              <b-col sm="10" class>
                <div class="font-weight-bold">
                  <div class="products">{{ itm.item_type_name }}</div>
                </div>
              </b-col>
              <b-col sm="2" class>
                <b-form-input :id="`item_count_${index}`" placeholder v-model="placementItems[index].pfd_count"
                  class="form-control" type="number"></b-form-input>
              </b-col>
            </b-row>
          </div>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
            translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
            translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-placement-items',
  mixins: [translationMixin],
  data() {
    return {
      allItems: [
        {
          item_type_name: '',
          pfd_key: null,
          pfd_plf_org_key: null,
          pfd_pit_key: null,
          pfd_count: 0,
          pfd_nag_key: null,
          pfd_user: null,
          pfd_delete_flag: false,
        },
      ],
      allItemsTranslated: [],
      section2: true,
      toggle: true,
      toggleMe: true,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      editPlacementItems: 'scriptureDistribution/editPlacementItems',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      loadPlacementItems: 'scriptureDistribution/loadPlacementItems',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async handleCancelClick() {
      this.$router.push('/programs/sd/placement-items')
    },
    async handleSaveClick() {
      if (!this.validateForm()) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorCountsRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        let result = true
        let payload = this.placementItems.map((itm) => {
          return {
            pfd_key: itm.pfd_key,
            pfd_plf_org_key: itm.pfd_plf_org_key,
            pfd_pit_key: itm.pit_key,
            pfd_count: itm.pfd_count,
            pfd_nag_key: itm.pfd_nag_key,
            pfd_user: itm.pfd_add_user,
            pfd_delete_flag: false,
          }
        })

        // submit data
        await Promise.all([this.setLoadingStatus(true), (result = await this.editPlacementItems(payload))]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcPlacementSaved : this.translations.tcErrorDuringSave,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.$router.push('/programs/sd/placement-items')
            }
          })
        })
      }
    },
    validateForm() {
      let rtn = true
      try {
        this.placementItems.forEach((itm) => {
          if (itm.pfd_count === null || itm.pfd_count < 0) {
            rtn = false
            throw 'invalid item value'
          }
        })
      } catch {}
      return rtn
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: null }),
          this.loadPlacementItems(this.userSelectedLocationKey),
        ]).then(() => {
          this.allItems = { ...this.placementItems }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    translatePlacementItems() {
      if (!this.translations.common || !this.placementItems) return false

      return (this.allItemsTranslated = this.placementItems.map((item) => {
        const itemGuid = item.pit_key || ''
        const itemName = this.translations.common['placement-item-types'].hasOwnProperty(itemGuid)
          ? this.translations.common['placement-item-types'][itemGuid]
          : item.item_type_name

        return { ...item, item_type_name: itemName }
      }))
    },
  },
  async created() {
    try {
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('common.placement-item-types'),
        this.pageLoad(),
      ]).then((results) => {
        this.stripReadableText(results[1])
        const translatedText = { common: { ...this.translations.common } }
        this.$set(this.translations, 'components', translatedText)
        this.translatePlacementItems()
      })
    } catch (e) {
      console.error('Error in EditPlacementItems.vue, created(), ', e)
    }
  },
  computed: {
    ...mapGetters({
      locationDetail: 'scriptureDistribution/locationDetail',
      placementItems: 'scriptureDistribution/placementItems',
      prefCulture: 'user/userPreferredCulture',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
    }),
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.participant {
  width: 280px;
  display: inline-block;
}

.products {
  width: 280px;
  display: inline-block;
}

.clockIcon {
  border: 1px solid #636363;
  border-right: 0px;
  width: 51px;
  height: 51px;
  display: inline;
  padding: 10px;
  position: relative;
}

.list-border {
  border-bottom: 1px solid #979797;
  margin-bottom: 44px;
  padding-bottom: 22px;

  // @include breakpoint(sm) {
  //   columns: 1;
  //   column-gap: 0;
  // }
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  //max-width: 55px;
  min-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input {
  border: 1px solid #636363;
  border-left-width: 0 !important;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  // width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

